.upgrade-plan {
    position: relative;
    @apply bg-gray-100;


    input,
    select,
    textarea {
        @apply bg-gray-100 border-gray-300;
    }

    .upgrade-plan-disabled {
        input,
        select,
        button,
        textarea label {
            pointer-events: none;
            cursor: not-allowed;
            user-select: none;
        }
    }
}
