.phone-widget {
    position: relative;
    margin-inline: auto;
    width: 100%;
    max-width: 400px;
    overflow: scroll;
    height: 800px;
    overflow: hidden;

    &:before {
        z-index: -1;
        position: absolute;
        margin: 5px;
        top: 0;
        width: calc(100% - 10px);
        background: #777;
        content: "";
        border-radius: 57px;
    }

    .phone {
        position: absolute;
        width: 100%;
        height: 100%;
        background: url(../../assets/images/iphone.png);
        background-size: 100%;
        background-repeat: no-repeat;
    }

    .phone-light {
        position: absolute;
        z-index: -1;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: url(../../assets/images/iphone-light.png);
        background-size: 100%;
        background-repeat: no-repeat;
    }

    .lock {
        height: 0;
        overflow: visible;
        z-index: 0;
    }

    .lock .clock {
        position: relative;
        z-index: 1;
        height: 170px;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
        justify-content: center;
        color: white;
        font: 100 48px sans-serif;
    }

    .lock .time {
        width: 100%;
        text-align: center;
    }

    .lock .date {
        font-size: 18px;
    }

    .lock .bubble {
        position: relative;
        z-index: 1;
        margin: 35px;
        min-height: 30px;
        width: auto;
        padding: 10px 15px 15px 10px;
        background: rgba(210, 210, 210, 0.7);
        border-radius: 14px;
        min-height: 50px;
        cursor: pointer;
    }

    .lock .bubble.swipe {
        animation: swipe 0.5s forwards;
    }

    @keyframes swipe {
        from {
            transform: translate(0);
            opacity: 1;
        }

        to {
            transform: translate(-100%);
            opacity: 0;
        }
    }

    .lock .messages-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 22px;
        padding-left: 32px;
        color: rgba(0, 0, 0, 0.5);
        background-size: 22px 22px;
        background-repeat: no-repeat;
        background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='250.001' height='250.001' viewBox='0 0 66.146 66.146'%3E%3Cdefs%3E%3ClinearGradient id='A' x1='-25.885' y1='207.601' x2='-25.885' y2='154.055' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%230cbd2a'/%3E%3Cstop offset='1' stop-color='%235bf675'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cg transform='translate(59.483 -145.846)'%3E%3Crect rx='14.568' y='145.846' x='-59.483' height='66.146' width='66.146' fill='url(%23A)' paint-order='markers stroke fill'/%3E%3Cpath d='M-26.4 157.296a24.278 20.222 0 0 0-24.278 20.222 24.278 20.222 0 0 0 11.795 17.316 27.365 20.222 0 0 1-4.245 5.942 23.857 20.222 0 0 0 9.86-3.874 24.278 20.222 0 0 0 6.868.838 24.278 20.222 0 0 0 24.278-20.222 24.278 20.222 0 0 0-24.278-20.222z' fill='%23fff' paint-order='markers stroke fill'/%3E%3C/g%3E%3C/svg%3E");
    }

    .lock .message-row {
        display: flex;
        align-items: flex-end;
    }

    .message-wrapper {
        width: 100%;
        flex-grow: 1;
    }

    .lock .message-from {
        font-weight: bold;
        margin-top: 5px;
        line-height: 1.8em;
        color: rgba(0, 0, 0, 0.9);
    }

    .lock .message-body {
        line-height: 1.2em;
        color: rgba(0, 0, 0, 0.9);
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow-wrap: anywhere;
        white-space: pre-line;
    }

    .lock .image img {
        width: 40px;
        height: 40px;
        object-fit: cover;
        margin-bottom: -5px;
    }

    .messages {
        min-height: 330px;
        height: 100%;
        z-index: 1;
        position: relative;
        overflow: visible;
        padding: 0 27px 0 27px;
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.5s;
        transition-delay: 0s;
        padding-bottom: 42px;
    }

    .messages.show {
        opacity: 1;
        pointer-events: auto;
        transition-delay: 0.25s;
    }

    .messages .back-button {
        position: absolute;
        left: 25px;
        top: 45px;
        width: 75px;
        height: 45px;
        cursor: pointer;
    }

    .messages .message-from {
        text-align: center;
        padding-top: 50px;
        line-height: 37px;
        color: rgba(0, 0, 0, 0.7);
    }

    .messages .message-container {
        display: flex;
        max-height: 651px;
        min-height: 220px;
        overflow: auto;
        padding: 20px 10px 0 10px;
        border-radius: 0 0 33px 33px;

        @media (max-width: 525px) {
            padding: 10px 5px 0 5px;
            max-height: 560px;
        }
        @media (max-width: 320px) {
            max-height: 460px;
        }
    }

    .messages .image {
        max-width: 80%;
        min-height: 35px;
        background: rgba(210, 210, 210, 0.7);
        border-radius: 14px;
        overflow: hidden;
        line-height: 0;
        margin-top: 2px;
    }

    .messages .image img {
        width: 100%;
        max-height: 300px;
        object-fit: cover;
    }

    .messages .msg-bubble {
        position: relative;
        margin-top: 2px;
        width: 100%;
        background: #e0e0e0;
        border-radius: 14px;
        overflow: hidden;
    }

    .messages .tail {
        position: relative;
        top: -20px;
        left: -6px;
    }

    .messages .message {
        padding: 10px;
        overflow-wrap: anywhere;
        white-space: pre-line;
        @media (max-width: 525px) {
            padding: 7px;
        }
        a {
            text-decoration: underline;
            color: #1c81f5;
        }
    }

    .segmentInfo {
        position: relative;
        width: 400px;
        margin: auto;
        text-align: center;
        padding: 8px 0;
        margin-top: 20px;
        background: rgba(20, 172, 228, 0.15);
        color: #14ace4;
        border-radius: 5px;
    }

    .segmentInfo svg {
        margin: 0 0 -4px 12px;
        color: #14ace4;
    }

    .segmentInfo a {
        position: absolute;
        line-height: 35px;
        right: 10px;
    }

    .warn {
        background: rgba(249, 151, 45, 0.15);
        color: #f9972d;
    }

    .warn svg {
        color: #f9972d;
    }

    .danger {
        background: rgba(255, 49, 102, 0.15);
        color: #f8564e;
    }

    .danger svg {
        color: #f8564e;
    }
}
