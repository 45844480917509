@import "components/global";

.plan-card {
    background-color: #233447 ;
    color: #fff;
    padding: 1rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 15rem;

    p {
        color: #ffffffd0;
    }

    .plan-price {
        @apply mt-5 flex items-start gap-4;

        h1 {
            @apply font-semibold text-3xl;
        }

        h2{
            @apply font-semibold text-lg;
        }

        span {
            color: #ffffffd0;
            font-size: $scale-1;
            margin-top: .5rem;
        }

        table{
            td{
                @apply pr-2;
            }
        }
    }
}
