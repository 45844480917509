.base-angled-badge {
    position: relative;
    width: 100%;
}

.angled-badge {
    position: absolute;
    width: 200px;
    height: 200px;
    overflow: hidden;

    &::before {
        content: attr(data-text);
        background: #038fcc;
        color: #fff;
        font-weight: bold;
        font-family: 'Helvetica Neue', sans-serif;
        font-size: 13px;
        display: block;
        position: relative;
        width: 200px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        cursor: default;
    }

    &.angled-badge-top-right {
        top: 0;
        right: 0;
    }

    &.angled-badge-top-right::before {
        top: 40px;
        right: -45px;
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
    }

    &.angled-badge-top-left {
        top: 0;
        left: 0;
    }

    &.angled-badge-top-left:before {
        top: 40px;
        left: -45px;
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
    }
}