@tailwind base;
@tailwind components;
@tailwind utilities;


html {
  @apply text-[15px] sm:text-[13px] 2xl:text-[16px];
}

body {
  overflow-x: hidden;
}

html,
#root,
body {
  height: 100%;
}

.font-roboto {
  font-family: "Roboto", sans-serif;
}

.font-open_sans_3 {
  font-family: "Source Sans 3", sans-serif;
}


.light-scrollbar {
  scrollbar-width: auto;
  scrollbar-color: #000000 #ffffff;
}

.light-scrollbar::-webkit-scrollbar {
  width: 12px;
}

.light-scrollbar::-webkit-scrollbar-track {
  background: transparent;
}

.light-scrollbar::-webkit-scrollbar-thumb {
  background-color: #bbbbbb;
  border-radius: 9px;
  border: 2px solid #ffffff;
}

.light-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #acacac;
}

.hide-accordion-icon>span {
  display: none;
}

.avatars {
  display: inline-flex;
  flex-direction: row-reverse;
}

.avatars .avatar {
  position: relative;
  border: 2px solid #fff;
  border-radius: 50%;
  overflow: hidden;
  width: 32px;
  height: 32px;
}

.avatars .avatar:not(:last-child) {
  margin-left: -50px;
}

.avatars .avatar img {
  width: 100%;
  display: block;
}

@layer base {
  body {
    color: var(--gray-900, hsl(0, 0%, 20%));
    font-family: "Roboto", sans-serif;
  }

  *,
  *:after,
  *:before {
    @apply bg-no-repeat;
  }

  article p {
    @apply mb-3;
  }

  button {
    font-family: "Source Sans 3", sans-serif !important;
  }

  textarea::placeholder,
  input::placeholder {
    color: #B1B1B3 !important;
  }
/* 
  a {
    color: #2076C2 !important;
  } */

  :not(button):disabled {
    color: #676879 !important;
  }
}

@layer components {
  table tbody tr:last-child td {
    @apply border-0 !important;
  }
}