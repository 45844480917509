@import "components/global.scss";

.accordion {
    .rc-accordion-toggle {
        display: flex;
        align-items: center;
        cursor: pointer;
        justify-content: space-between;
        background-color: #ebedf0;
        transition: 0.3s;
    }
    .rc-accordion-toggle.active {
        background-color: $c-blue;
    }
    .rc-accordion-toggle.active .rc-accordion-icon {
        transform: rotate(180deg);
        color: #fff;
    }
    .rc-accordion-card {
        border: 1px solid #ddd;
        border-radius: 5px;
        margin-bottom: 10px;
        overflow: hidden;
    }
    .rc-accordion-card:last-child {
        margin-bottom: 0;
    }
    .rc-accordion-title {
        font-weight: 600;
        position: relative;
        margin-bottom: 0;
        color: #475f7b;
        transition: 0.3s;
    }
    .rc-accordion-toggle.active .rc-accordion-title {
        color: #fff;
    }
    .rc-accordion-body {
        flex: 1 1 auto;
        min-height: 1px;
        padding: 15px;
    }
    .rc-accordion-body p {
        margin-bottom: 0;
        font-weight: 400;
        line-height: 24px;
    }
    .rc-collapse {
        position: relative;
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.35s ease;
    }
    .rc-collapse.show {
        max-height: 1440px;
    }
}
