/* Div contenteditable */
.prompt {
    position: relative;
    width: 100%;
    height: 100%;
    outline: none;
    caret-color: black;
}

.prompt:focus {
    outline: none;
}

/* Tag */
.prompt > [data-tag-id] {
    display: inline-block;
    white-space: nowrap;
    border-radius: 7px;
    padding: 0 10px;
    margin: 0 1px;
    background: #BBDEFB;
    font-weight: 600;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.6);
    text-overflow: ellipsis;
    word-wrap: break-word;
    vertical-align: bottom;
    overflow: hidden;
    transition: border 0.2s linear;
    cursor: move;
}

/* Tag label */
.prompt > [data-tag-id]::before {
    content: attr(data-tag-label);
}

/* Remove button */
.prompt > [data-tag-id] > button[data-tag-action='remove'] {
    background: none;
    border: none;
    outline: none;
    margin-left: 4px;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
    cursor: pointer;
}

/* Remove icon */
.prompt > [data-tag-id] > button[data-tag-action='remove']::after {
    content: 'x';
    font-size: 13px;
    font-weight: 800;
}
