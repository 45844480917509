@import "components/global.scss";

.pricing-plan-card {
    width: 100%;

    .generic_content {
        background-color: #fff;
        width: 100%;
        border: 1px solid rgba($c-blue, 0.3);
    }

    .generic_content .generic_head_price {
        background-color: #f6f6f6;
    }

    .generic_content .generic_head_price .generic_head_content .head_bg {
        border-color: #e4e4e4 rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #e4e4e4;
    }

    .generic_content .generic_head_price .generic_head_content .head span {
        color: #525252;
    }

    .generic_content .generic_head_price .generic_price_tag .price .sign {
        color: #414141;
    }

    .generic_content .generic_head_price .generic_price_tag .price .currency {
        color: #414141;
    }

    .generic_content .generic_head_price .generic_price_tag .price .cent {
        color: #414141;
    }

    .generic_content .generic_head_price .generic_price_tag .month {
        color: #414141;
    }

    .generic_content .generic_feature_list ul li {
        padding: $scale;
        color: #a7a7a7;
    }

    .generic_content .generic_feature_list ul li span {
        color: #414141;
        display: flex;
        align-items: center;
        gap: 0.5rem;
        text-align: left;
    }
    .generic_content .generic_feature_list ul li:hover {
        background-color: #e4e4e4;
        border-left: 5px solid $c-blue;
    }

    .generic_content .generic_price_btn button {
        border: 1px solid $c-blue;
        color: $c-blue;
    }

    .generic_content.active .generic_head_price .generic_head_content .head_bg,
    .generic_content:hover .generic_head_price .generic_head_content .head_bg {
        border-color: $c-blue rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) $c-blue;
        color: #fff;
    }

    .generic_content:hover .generic_head_price .generic_head_content .head span,
    .generic_content.active .generic_head_price .generic_head_content .head span {
        color: #fff;
    }

    .generic_content:hover .generic_price_btn button,
    .generic_content.active .generic_price_btn button {
        background-color: $c-blue;
        color: #fff;
    }

    /*PRICE BODY CODE START*/

    .generic_content {
        overflow: hidden;
        position: relative;
        text-align: center;
    }

    .generic_content .generic_head_price {
        margin: 0 0 20px 0;
    }

    .generic_content .generic_head_price .generic_head_content {
        margin: 0 0 50px 0;
    }

    .generic_content .generic_head_price .generic_head_content .head_bg {
        border-style: solid;
        border-width: 90px 1411px 23px 399px;
        position: absolute;
    }

    .generic_content .generic_head_price .generic_head_content .head {
        padding-top: 40px;
        position: relative;
        z-index: 1;
    }

    .generic_content .generic_head_price .generic_head_content .head span {
        font-size: $scale2;
        font-weight: 400;
        letter-spacing: 2px;
        margin: 0;
        padding: 0;
        text-transform: uppercase;
    }

    .generic_content .generic_head_price .generic_price_tag {
        padding: 0 0 $scale1;
    }

    .generic_content .generic_head_price .generic_price_tag .price {
        display: block;
    }

    .generic_content .generic_head_price .generic_price_tag .price .sign {
        display: inline-block;
        font-size: $scale2;
        font-weight: 400;
        vertical-align: middle;
    }

    .generic_content .generic_head_price .generic_price_tag .price .currency {
        font-size: $scale6;
        font-weight: 300;
        letter-spacing: -2px;
        line-height: 80px;
        padding: 0;
        vertical-align: middle;
    }

    .generic_content .generic_head_price .generic_price_tag .price .cent {
        display: inline-block;
        font-size: $scale2;
        font-weight: 400;
        vertical-align: bottom;
    }

    .generic_content .generic_head_price .generic_price_tag .month {
        font-size: $scale;
        font-weight: 400;
        letter-spacing: 3px;
        vertical-align: bottom;
    }

    .generic_content .generic_feature_list ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    .generic_content .generic_feature_list ul li {
        font-size: $scale;
        transition: all 0.3s ease-in-out 0s;
    }

    .generic_content .generic_feature_list ul li:hover {
        transition: all 0.3s ease-in-out 0s;
        -moz-transition: all 0.3s ease-in-out 0s;
        -ms-transition: all 0.3s ease-in-out 0s;
        -o-transition: all 0.3s ease-in-out 0s;
        -webkit-transition: all 0.3s ease-in-out 0s;
    }

    .generic_content .generic_price_btn {
        margin-top: $scale3;
    }

    .generic_content .generic_price_btn button {
        width: 100%;
        display: inline-block;
        font-size: $scale;
        outline: medium none;
        padding: $scale $scale2;
        text-decoration: none;
        text-transform: uppercase;
    }

    .generic_content,
    .generic_content:hover,
    .generic_content .generic_head_price .generic_head_content .head_bg,
    .generic_content:hover .generic_head_price .generic_head_content .head_bg,
    .generic_content .generic_head_price .generic_head_content .head h2,
    .generic_content:hover .generic_head_price .generic_head_content .head h2,
    .generic_content .price,
    .generic_content:hover .price,
    .generic_content .generic_price_btn button,
    .generic_content:hover .generic_price_btn button {
        transition: all 0.3s ease-in-out 0s;
        -moz-transition: all 0.3s ease-in-out 0s;
        -ms-transition: all 0.3s ease-in-out 0s;
        -o-transition: all 0.3s ease-in-out 0s;
        -webkit-transition: all 0.3s ease-in-out 0s;
    }
}
